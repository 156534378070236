@import "../../node_modules/bulma/sass/utilities/initial-variables";

@font-face {
  font-family: "TTTrailers";
  src: url(../fonts/TTTrailers-DemiBold.woff);
  font-display: swap;
}

/* overwrite Bulma Variables */
// see https://bulma.io/documentation/customize/variables/
$text-strong: $black;
$gap: 16px;
$family-primary: "Nunito", Helvetica, sans-serif;
$color: white;
$body-background-color: #323638;
$body-color: #ffffff;
$tablet: 860px;
$brand-color: #ff3d1e;

@import "../../node_modules/bulma/bulma";

/*start custom SCSS */
p {
    margin-bottom: 1.25rem;
}

.font-secondary {
    font-family: "TTTrailers", Helvetica, sans-serif;
}

.button.is-primary {
    border: none;
    background-color: $brand-color;
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1rem;
    padding: 1rem;
    height: auto;
    border-radius:0;
}

.button.is-primary:hover, 
.button.is-primary.is-hovered,
.button.is-primary:active, 
.button.is-primary.is-active,
.button.is-primary:focus:not(:active), 
.button.is-primary.is-focused:not(:active) {
    background-color: $brand-color;
    outline: none;
    box-shadow: none;
}

@media(min-width:860px) {
    .padding-right--big{
        padding-right: 5.625rem;
    }
}

/* hero section START*/
.hero {
    background-image: url(../images/frincks_hero@1x.png);
    background-image: image-set(url("../images/frincks_hero@1x.png") 1x, url("../images/frincks_hero@2x.png") 2x, url("../images/frincks_hero@1x.webp") 1x, url("../images/frincks_hero@2x.webp") 2x);
    background-image: -webkit-image-set( url("../images/frincks_hero@1x.png") 1x, url("../images/frincks_hero@2x.png") 2x, url("../images/frincks_hero@1x.webp") 1x, url("../images/frincks_hero@2x.webp") 2x);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
    position: relative;

    @include from(1023px) {
        max-height: 15.625rem;
    }
}

.hero:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6.25rem;
    background: linear-gradient(transparent, #323638);
}

.hero .container {
    width: 100%;
}

.logo {
    max-width: 8.875rem;

    @include from(860px) {
        max-width: 15.5rem;
    }
}

@media (max-width: 859px) and (orientation: landscape) {
    .hero {
        height: 45vh;
    }
}
/* hero section END*/

/* main section START*/
.main-content {
    padding-top: 4.5rem;
}

.main-content__copy-text {
    position: relative;
}

.main-content__copy-text .survey-logo {
    display: block;
    position: absolute;
    top: -7rem;
    z-index: 2;
    max-width: 21rem;
    width: 100%;
    @include until(380px) {
        max-width: 17.5rem;
    }

    @include from(1280px) {
        top: -7.25rem;
    }
}

.main-content__copy-text .survey-logo img {
    width: 100%;
}

.main-content__survey-info {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    border: 1px solid #707070;
}

.main-content__survey-info > span {
    padding-left: 1rem;
}

.main-content__about-section h2 {
    font-size: 3.75rem;
    font-weight: 600;
    line-height: 1.3;
}

.main-content__about-section .column img {
    max-width: 5rem;
}

.main-content__about-section .column p {
    color: #c1bca4;
}
/* main section END*/

/* side section START*/
.side-content__voucher-info {
    background: center / contain url(../images/frincks_main-img.png) no-repeat;
    background-color: #000000;
    height: 12.5rem;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include from(1024px) {
        height: 20.3125rem;
    }
}

.side-content__voucher-info .voucher-info {
    background-color: #323638;
    padding: .3125rem 1.25rem .125rem;
    color: $brand-color;
    text-transform: uppercase;
    font-size: 1.875rem;
    line-height: 1;
    height: max-content;
    width: 44%;
}

.side-content__voucher-info span {
    position: absolute;
    bottom: 0;
    opacity: .6;
    font-size: .875rem;
}

.side-content .button.is-primary {
    font-size: 1.25rem;
}

.side-content__inner {
    text-align: center;
}

@media(min-width: 860px) {
    .side-content__inner {
        margin-top: -3.925rem;
        position: fixed;
    }
}
/* side section END*/

/* about section START*/
.about-section {
    padding-bottom: 15rem;

    @include from(360px) {
        padding-bottom: 13.75rem;
    }

    @include from(560px) {
        padding-bottom: 11.25rem;
    }

    @include from(860px) {
        padding-bottom: 3.75rem;
    }
}

.about-section__info h2 {
    font-size: 3.75rem;
    text-transform: uppercase;
    line-height: 1.3;
    padding-bottom: .625rem;
}

.about-section .column {
    text-align: center;
}

.about-section .column p {
    color: #c1bca4;
    line-height: 1.3;

}

@media (max-width: 360px) {
    .about-section img {
        max-width: 4.375rem;
    }
}
/* about section END*/

/* bottom section START*/
.fixed-bottom-section {
    position: fixed;
    background-color: #000000;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    padding: 1rem;
    z-index: 2;
}

.fixed-bottom-section .fixed-bottom-section__inner {
    border: 1px solid #ffffff;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.fixed-bottom-section .button.is-primary {
    padding: .5rem;
    width: 100%;
}

.fixed-bottom-section .voucher-danger {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: $brand-color;
}

/* bottom section END*/

/* Survey */
.survey-popup {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 3rem;
    max-width: 600px;
    width: calc(100% - 2rem);
    z-index: 25111988;
    background-color: #ffffff;
    color: #323638;
    font-family: "Nunito", sans-serif;
    font-size: 1.125rem;
    line-height: 1.25rem;
    text-align: center;
}

.survey-popup__close {
    position: absolute;
    overflow: hidden;
    top: 22px;
    right: 18px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    z-index: 3;
    mix-blend-mode: luminosity;
}

.survey-popup__close::before,
.survey-popup__close::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #666;
    border-radius: 2px;
}

.survey-popup__close::before {
    -webkit-transform: rotate(
        45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(
        45deg);
}

.survey-popup__close::after {
    -webkit-transform: rotate(
        -45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(
        -45deg);
}